import { useState } from "react"

import {
  TThermostatSettingsAnalytics,
  usePostThermostatSettingsEdited,
} from "src/data/analytics/queries/integrationAnalyticsQueries"
import { TThermostatMode } from "src/data/homes/types/homeTypes"
import {
  TAvailableThermostatMode,
  TIntegration,
  TIntegrationConnectionErrorResponse,
  TIntegrationEntities,
  TIntegrationFilter,
  TIntegrationLinks,
  TIntegrationStatic,
  TThermostatSettings,
} from "src/data/integrations/types/integrationTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { usePatchHome } from "src/data/organizations/queries/homeQueries"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { getTemperatureUnitWithFallback } from "src/data/user/logic/userTemperature"
import { langKeys } from "src/i18n/langKeys"
import { TTranslateFunction } from "src/i18n/useTranslate"
import AutomationIcon from "src/ui/icons/automation.svg"
import HVACIcon from "src/ui/icons/climate-control.svg"
import GXPIcon from "src/ui/icons/guest-experience-platforms.svg"
import OTAIcon from "src/ui/icons/online-travel-agency.svg"
import PMSIcon from "src/ui/icons/property-management-system.svg"
import LockIcon from "src/ui/icons/smart-lock.svg"
import { TAlert } from "src/ui/MBanner/MBanner"
import { convertTemperatureValue, toCelsius } from "src/utils/l10n"

/**
 * Used to determine if the integration is an inbound integration,
 * meaning that the connection is done from the external party, not from
 * our side
 * @returns true if the integration supports the external_link operation
 */
export function isExternalLinkIntegration(integration: TIntegration): boolean {
  return !!integration.operations?.some(
    (operation) => operation.type === "external_link"
  )
}

const ota: TIntegrationFilter = {
  id: "ota",
  name: (t) => t(langKeys.integrations_group_ota_title),
  value: "ota",
  unique: true,
  icon: OTAIcon,
}

const pms: TIntegrationFilter = {
  id: "pms",
  name: (t) => t(langKeys.integrations_group_pms_title),
  value: "pms",
  unique: true,
  icon: PMSIcon,
}

const customAutomation: TIntegrationFilter = {
  id: "automation",
  name: (t) => t(langKeys.integrations_group_custom_title),
  value: "automation",
  unique: true,
  icon: AutomationIcon,
}

const smartLock: TIntegrationFilter = {
  id: "lock",
  name: (t) => t(langKeys.integrations_group_lock_title),
  value: "lock",
  unique: true,
  icon: LockIcon,
}

const hvac: TIntegrationFilter = {
  id: "hvac",
  name: (t) => t(langKeys.integrations_group_hvac_title),
  value: "hvac",
  unique: true,
  icon: HVACIcon,
}

const gxp: TIntegrationFilter = {
  id: "guest_experience",
  name: (t) => t(langKeys.integrations_group_gxp_platforms_title),
  value: "guest_experience",
  unique: true,
  icon: GXPIcon,
}

export const integrationFiltersList: TIntegrationFilter[] = [
  ota,
  pms,
  customAutomation,
  smartLock,
  hvac,
  gxp,
]

export function isStaticIntegration(
  i: TIntegrationStatic | TIntegration
): i is TIntegrationStatic {
  return (i as TIntegrationStatic).static === true
}

export function getConnectedStateColor({
  linksCount,
  isExternal,
  isBrokenIntegration,
  hasBrokenLinks,
}: {
  linksCount: number
  isExternal: boolean
  isBrokenIntegration: boolean
  hasBrokenLinks: boolean
}): TAlert {
  if (isBrokenIntegration) {
    return "error"
  }

  if ((isExternal || linksCount > 0) && !hasBrokenLinks) {
    return "good"
  }

  return "warning"
}

export function showIntegrationProgressData({
  homesCount,
  isExternal,
  isConfigured,
  error,
}: {
  homesCount: number
  isExternal: boolean
  isConfigured: boolean
  error: boolean
}) {
  return homesCount > 0 && !isExternal && isConfigured && !error
}

export function getCommonAvailableThermostatModes(
  devices: TIntegrationEntities[number]["devices"]
) {
  const modes = Array.from(
    new Set(devices?.flatMap((d) => d.available_modes || []))
  )

  const commonModes = modes.filter((mode) =>
    devices?.every((d) => d.available_modes?.includes(mode))
  )

  return commonModes
}

export function getDefaultThermostatMode(
  availableThermostatModes: TAvailableThermostatMode[]
): TAvailableThermostatMode {
  if (availableThermostatModes?.includes("heat")) {
    return "heat"
  }

  if (availableThermostatModes?.includes("cool")) {
    return "cool"
  }

  return "heatcool"
}

export function filterUnlinkedIntegrationEntites(
  integrationEntities: TIntegrationEntities,
  integrationLinks: TIntegrationLinks
) {
  return integrationEntities.filter(
    (entity) =>
      !integrationLinks.find((link) => entity.entity_id === link.entity_id)
  )
}

export function checkBrokenIntegration(integration: TIntegration) {
  return !!findBrokenConnection(integration)
}

export function findBrokenConnection(integration: TIntegration) {
  return (integration.connection_status || []).find(
    (i) => i.status === "broken"
  )
}

export function findUpdateAvailableConnection(integration: TIntegration) {
  return (integration.connection_status || []).find(
    (s) => s.status === "update_available"
  )
}

export function amountOfConnections(integration: TIntegration) {
  return integration.connection_status?.length || 0
}

export function brokenIntegrationBodyText(
  t: TTranslateFunction,
  integration: TIntegration,
  email?: string
) {
  if (email) {
    return t(langKeys.integrations_connection_broken_banner_details, {
      email,
      integration_name: integration.name,
    })
  } else {
    return t(langKeys.integrations_connection_broken_banner_generic)
  }
}

export function translateConnectionErrorKey({
  t,
  errorKey,
  integration,
}: {
  t: TTranslateFunction
  errorKey: TIntegrationConnectionErrorResponse["error_key"]
  integration: TIntegration
}) {
  if (errorKey === "invalid_credentials") {
    return t(langKeys.integrations_connection_error_invalid_credentials, {
      name: integration.name,
    })
  }

  if (errorKey === "secret_already_used") {
    return t(langKeys.integrations_connection_error_secret_already_used, {
      name: integration.name,
    })
  }

  if (errorKey === "properties_required_to_connect") {
    return t(
      langKeys.integrations_connection_error_properties_required_to_connect,
      { name: integration.name }
    )
  }

  return t(langKeys.failed_general_error_try_refreshing_page)
}

export function translateThermostatMode(
  mode: TAvailableThermostatMode | undefined,
  t: TTranslateFunction
) {
  if (!mode) {
    return t(langKeys.none)
  }

  if (mode === "heat") {
    return t(langKeys.integrations_thermostat_mode_heat)
  }

  if (mode === "cool") {
    return t(langKeys.integrations_thermostat_mode_cool)
  }

  return t(langKeys.integrations_thermostat_mode_heatcool)
}

export function hasSupportedHVACFeatures(integration: TIntegration) {
  const excludedHVACIntegrations: TIntegration["integration_identifier"][] = [
    "tado",
  ]

  return (
    integration.type === "hvac" &&
    !excludedHVACIntegrations.includes(integration.integration_identifier)
  )
}

export function useUpdateThermostatSettings({
  modeOnCheckin,
  setPoints,
  turnOnTime,
  homeId,
  afterSaveSuccess,
  trackIntegration,
}: {
  modeOnCheckin?: TThermostatMode
  setPoints?: TThermostatSettings["setPoints"]
  turnOnTime?: number
  homeId?: string
  afterSaveSuccess?: () => void
  trackIntegration: TThermostatSettingsAnalytics
}) {
  const HEAT_TEMP_DEFAULT = 20 // in celsius
  const COOL_TEMP_DEFAULT = 24 // in celsius
  const DEFAULT_PRE_HEAT_COOL_TIME = 180 // in minutes

  const { orgId } = useOrganization()
  const user = useGetUser()
  const temperatureUnit = getTemperatureUnitWithFallback(user)

  const postThermostatSettingsEdited = usePostThermostatSettingsEdited()

  const [selectedMode, setSelectedMode] = useState<TThermostatMode | undefined>(
    modeOnCheckin
  )

  const [temperatureControlValues, setTemperatureControlValues] = useState({
    heatTemperature: Number(
      convertTemperatureValue(
        setPoints?.heat_temperature || HEAT_TEMP_DEFAULT,
        temperatureUnit,
        0
      )
    ),
    coolTemperature: Number(
      convertTemperatureValue(
        setPoints?.cool_temperature || COOL_TEMP_DEFAULT,
        temperatureUnit,
        0
      )
    ),
    preHeatCoolTime: (turnOnTime || DEFAULT_PRE_HEAT_COOL_TIME) / 60,
  })

  const patchHome = usePatchHome()

  async function handleSave() {
    if (!homeId) {
      return
    }

    const { heatTemperature, coolTemperature, preHeatCoolTime } =
      temperatureControlValues

    // Use decimals when converting F to C in order to not lose precision when user inputs numbers in F
    const heatTemperatureCelsius =
      temperatureUnit === "F"
        ? toCelsius({ value: heatTemperature, decimals: 1 })
        : heatTemperature

    const coolTemperatureCelsius =
      temperatureUnit === "F"
        ? toCelsius({ value: coolTemperature, decimals: 1 })
        : coolTemperature

    patchHome.mutateAsync({
      orgId,
      homeId,
      data: {
        thermostat_temperature_setpoint_on_checkin: {
          heat_temperature: Number(heatTemperatureCelsius),
          cool_temperature: Number(coolTemperatureCelsius),
        },
        thermostat_turn_on_in_minutes_before_checkin: preHeatCoolTime * 60,
        thermostat_mode_on_checkin: selectedMode,
      },
    })

    postThermostatSettingsEdited.mutate({
      integrations: trackIntegration,
    })

    afterSaveSuccess?.()
  }

  return {
    temperatureControlValues,
    setTemperatureControlValues,
    temperatureUnit,
    selectedMode,
    setSelectedMode,
    handleSave,
    patchHome,
  }
}
